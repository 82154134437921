import * as Sentry from '@sentry/vue';
import {
  type Invitation,
  type UserOnboardingData,
  TN_ERROR_CODES,
} from '@tn/shared';
import {
  signInWithEmailAndPassword,
  signInWithCustomToken,
  signOut,
  getAuth,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  sendEmailVerification,
  type AuthError,
} from 'firebase/auth';
import EVENTS from '~/constants/events';
import { emailFromStorage } from '~/constants/storage';
import { getRootUrl } from './url';

export const sendMagicLinkToEmail = async (email: string) => {
  if (!email) {
    throw createError({
      statusCode: 500,
      message: 'Missing email address',
      data: { code: TN_ERROR_CODES.MISSING_EMAIL },
    });
  }

  const auth = getAuth();

  if (!auth)
    throw createError({
      statusCode: 500,
      message: 'Firebase auth is not initialized',
    });

  try {
    await sendSignInLinkToEmail(auth, email, {
      url: window.location.href,
      handleCodeInApp: true,
    });
    emailFromStorage.value = email;
  } catch (error) {
    const typedError = error as AuthError;
    throw createError({
      statusCode: 500,
      message: typedError.message,
      data: { code: typedError.code },
    });
  }
};

export const emailLogin = async (email: string, password: string) => {
  if (!email || !password) {
    throw createError({
      statusCode: 500,
      message: 'Missing email or password',
      data: { code: TN_ERROR_CODES.MISSING_EMAIL_PASSWORD },
    });
  }

  const auth = getAuth();

  if (!auth) {
    throw createError({
      statusCode: 500,
      message: 'Firebase auth is not initialized',
    });
  }

  try {
    const result = await signInWithEmailAndPassword(auth, email, password);
    return result.user;
  } catch (error) {
    const typedError = error as AuthError;
    throw createError({
      statusCode: 500,
      message: typedError.message,
      data: { code: typedError.code },
    });
  }
};

export const customTokenLogin = async (token: string) => {
  if (!token) {
    throw createError({
      statusCode: 500,
      message: 'Missing token',
      data: { code: 'missing_token' },
    });
  }

  const auth = getAuth();

  if (!auth)
    throw createError({
      statusCode: 500,
      message: 'Firebase auth is not initialized',
    });

  try {
    return await signInWithCustomToken(auth, token);
  } catch (error) {
    const typedError = error as AuthError;
    throw createError({
      statusCode: 500,
      message: typedError.message,
      data: { code: typedError.code },
    });
  }
};

export const emailSignup = async (
  email: string,
  password: string,
  onboarding: UserOnboardingData
) => {
  if (!email || !password) {
    throw createError({
      statusCode: 500,
      message: 'Missing email and password',
      data: { code: TN_ERROR_CODES.MISSING_EMAIL_PASSWORD },
    });
  }

  const { data } = await postSignup({
    email,
    password,
    onboarding,
  });

  const { success, error } = data;

  if (!success)
    throw createError({
      statusCode: 500,
      message: error?.message || 'Signup failed',
      data: { code: error?.code },
    });

  const user = await emailLogin(email, password);
  const redirectUrl = `${getRootUrl()}/members?email_verified=true`;
  await sendEmailVerification(user, {
    url: redirectUrl,
    handleCodeInApp: true,
  });
};

export const resetPassword = async (email: string) => {
  if (!email) {
    throw createError({
      statusCode: 500,
      message: 'Missing email',
      data: { code: TN_ERROR_CODES.MISSING_EMAIL },
    });
  }

  const auth = getAuth();

  if (!auth)
    throw createError({
      statusCode: 500,
      message: 'Firebase auth is not initialized',
    });

  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    const typedError = error as AuthError;
    throw createError({
      statusCode: 500,
      message: typedError.message,
      data: { code: typedError.code },
    });
  }
};

export const getInvitation = async (
  inviteToken: string
): Promise<Invitation | undefined> => {
  if (!inviteToken) {
    throw createError({
      statusCode: 500,
      message: 'Missing token',
      data: { code: TN_ERROR_CODES.MISSING_CODE },
    });
  }

  const { data } = await verifyInvitation({ inviteToken });
  const { success, error, invitation } = data;

  if (!success || !invitation) {
    let message = error?.message || 'Failed to verify invitation.';
    if (error?.code === TN_ERROR_CODES.INVITE_TOKEN_EXPIRED) {
      message += `
          <a href='https://carbmanager.typeform.com/to/QDpaZq4W?typeform-source=app.tastenetwork.io' target='_blank' style='text-decoration-line: underline;'> Click here to rejoin the waitlist</a>.
          If you already have an account, <a href='/members/login' style='text-decoration-line: underline;'>click here to log in</a>.
        `;
    }
    useNotifications().showErrorNotification({
      description: message,
      timeout: 0,
    });

    useNuxtApp().$trackEvent(EVENTS.ONBOARDING_FLOW_STARTED, {
      page_landed: 'expired_link',
    });
    return;
  }

  return invitation;
};

export const sendEmailVerificationLink = async () => {
  const auth = getAuth();
  if (!auth) {
    throw createError({
      statusCode: 500,
      message: 'Firebase auth is not initialized',
    });
  }
  const currentUser = auth.currentUser;
  if (!currentUser) {
    throw createError({
      statusCode: 500,
      message: 'No current user is logged in',
      data: { code: TN_ERROR_CODES.NULL_USER },
    });
  }

  try {
    await sendEmailVerification(currentUser);
  } catch (error) {
    const typedError = error as AuthError;
    throw createError({
      statusCode: 500,
      message: typedError.message,
      data: { code: typedError.code },
    });
  }
};
